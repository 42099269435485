/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from './layout';
import SEO from './seo';
import ScrollToLocationHash from './scroll-to-location-hash';

type PageProps = {
  data: {
    page: {
      title: string;
      slug: string;
      excerpt: string;
      body: string;
    };
  };
};

const Page = ({ data: { page } }: PageProps) => (
  <Layout>
    <ScrollToLocationHash />
    <SEO title={page.title} description={page.excerpt} pathname={page.slug} />
    <Heading variant="styles.h2">{page.title}</Heading>
    <section sx={{ my: 5 }}>
      <MDXRenderer>{page.body}</MDXRenderer>
    </section>
  </Layout>
);

export default Page;
