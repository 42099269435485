import React, { useEffect } from 'react';

const ScrollToLocationHash = () => {
  useEffect(() => {
    const hash = window.decodeURI(location.hash.replace('#', ''));
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ block: 'start' });
      }
    }
  }, []);

  return <></>;
};

export default ScrollToLocationHash;
